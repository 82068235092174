<template>
  <div class="activity-detail">
    活动详情
  </div>
</template>
<script>

export default {
  name: 'ActivityDetail',
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
  },
  created() {
  },
  methods: {
  }
}
</script>

<style lang='less' scope>
</style>
