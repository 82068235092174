<template>
  <div class="activity-list">
    <div class="top-search-wrapper">
      <TopSearch
        :tabs-data="tabsData"
        :top-search-map="topSearchMap"
        theme="day"
        @search="topSearch"
        @typeChange="typeChange"
      />
    </div>
    <div class="list-wrapper">
      <div v-if="list.length > 0">
        <div v-for="(item, index) in list" :key="index" class="list-body-card">
          <div class="card-wrapper">
            <div class="card-title">{{ item.title }}</div>
            <div class="card-top">
              <span class="name">{{ item.name }}</span>
              <span class="phone">{{
                item.phone.slice(0, 3) + '****' + item.phone.slice(7, 11)
              }}</span>
            </div>
            <div class="card-middle">
              <van-divider />
            </div>
            <div class="card-bottom">
              <span class="time">时间：{{ item.time }}</span>
            </div>
            <div class="btn">
              <div @click="send(item)">
                发送
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmptyError
        v-show="emptyErrorType != ''"
        :empty-error-type="emptyErrorType"
      />
    </div>
    <PopupFilter
      ref="popupFilter"
      :filter-field-map="filterFieldMap"
      :filter-item-arr="filterItemArr"
      :is-show="filterShow"
      @sure="popUpFilterSure"
      @reset="popUpFilterReset"
      @close="filterShow = false"
      @fieldValueChange="fieldValueChange"
    />
    <div class="pop-up-open-btn" @click="openPopUpFilter">
      筛选
    </div>
  </div>
</template>
<script>
import TopSearch from '@/components/TopSearch.vue'
import PopupFilter from '@/components/PopupFilter.vue'
import EmptyError from '@/components/EmptyError.vue'
import wxSDK from '@/scripts/wxsdk'
import { mapGetters } from 'vuex'
export default {
  name: 'ActivityList',
  components: {
    TopSearch,
    PopupFilter,
    EmptyError
  },
  data() {
    return {
      proj: global.APP_CONFIG.auth_type,
      list: [
        {
          title: '话术',
          name: '张三',
          phone: '137 **** 5600',
          time: '2021-02-03 12:33:22'
        },
        {
          title: '话术',
          name: '张三',
          phone: '137 **** 5600',
          time: '2021-02-03 12:33:22'
        },
        {
          title: '话术',
          name: '张三',
          phone: '137 **** 5600',
          time: '2021-02-03 12:33:22'
        }
      ],
      tabsData: [
        {
          name: '话术',
          value: '1'
        },
        {
          name: '文章',
          value: '2'
        }
      ],
      topSearchMap: {
        type: '1',
        keyWord: ''
      },
      filterFieldMap: {
        publishTime: '',
        publishTimeRange: '',
        firstGrounp: [],
        secondGrounp: [],
        thirdGrounp: []
      },
      filterItemArr: [
        {
          field: 'publishTime',
          type: 'radio',
          label: '发布时间',
          data: [
            { name: '最新', value: '最新' },
            { name: '最近一月', value: '最近一月' },
            { name: '最近半年', value: '最近半年' }
          ]
        },
        {
          field: 'publishTimeRange',
          type: 'timeRange',
          label: '按时间段'
        },
        {
          field: 'firstGrounp',
          type: 'checkbox',
          label: '一级分组',
          data: [
            { name: '分组1', value: '分组1' },
            { name: '分组2', value: '分组2' },
            { name: '分组3', value: '分组3' },
            { name: '分组4', value: '分组4' },
            { name: '分组5', value: '分组5' },
            { name: '分组6', value: '分组6' },
            { name: '分组7', value: '分组7' },
            { name: '分组8', value: '分组8' },
            { name: '分组9', value: '分组9' },
            { name: '分组10', value: '分组10' },
            { name: '分组11', value: '分组11' }
          ],
          collapseLength: 9
        },
        {
          field: 'secondGrounp',
          type: 'checkbox',
          label: '二级分组',
          data: [
            { name: '分组1', value: '分组1' },
            { name: '分组2', value: '分组2' },
            { name: '分组3', value: '分组3' }
          ],
          collapseLength: 9
        },
        {
          field: 'thirdGrounp',
          type: 'checkbox',
          label: '三级分组',
          data: [
            { name: '分组1', value: '分组1' },
            { name: '分组2', value: '分组2' }
          ],
          collapseLength: 9
        }
      ],
      filterShow: false,
      emptyErrorType: ''
    }
  },
  computed: {
    ...mapGetters({
      authorizeInfo: 'userAuth/getAuthorizeInfo',
      userInfo: 'userAuth/getUsersCurrentInfo'
    })
  },
  mounted() {},
  async created() {
    if (
      global.APP_CONFIG.auth_type === 'webAuthLogin' ||
      global.APP_CONFIG.auth_type === 'scanCodeAuthLogin'
    ) {
      await wxSDK.config([], ['sendChatMessage'])
    }
  },
  methods: {
    topSearch(topSearchMap) {
      this.topSearchMap = { ...topSearchMap }
      console.log(topSearchMap)
    },
    typeChange(topSearchMap) {
      this.topSearchMap = { ...topSearchMap }
      this.popUpFilterReset()
      console.log(topSearchMap)
    },
    openPopUpFilter() {
      this.filterShow = true
    },
    popUpFilterSure(obj) {
      this.filterFieldMap = { ...obj }
      window.console.log(obj)
      this.knowledgeSearch('reStart')
    },
    popUpFilterReset() {
      this.filterFieldMap = {
        publishTime: '',
        publishTimeRange: '',
        firstGrounp: [],
        secondGrounp: [],
        thirdGrounp: []
      }
      this.knowledgeSearch('reStart')
    },
    fieldValueChange(index, resultVal) {
      console.log(index, resultVal) // index数据变化项，resultVal数据变化结果
    },
    async send(item) {
      const contentJson = {
        msgtype: 'text', // 消息类型，必填
        enterChat: true, // 为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
        text: {
          content: item.content // 文本内容
        },
        image: {
          mediaid: '' // 图片的素材id
        },
        video: {
          mediaid: '' // 视频的素材id
        },
        file: {
          mediaid: '' // 文件的素材id
        },
        news: {
          link: '', // H5消息页面url 必填
          title: '', // H5消息标题
          desc: '', // H5消息摘要
          imgUrl: '' // H5消息封面图片URL
        }
        // miniprogram: {
        //   appid: 'wx8bd80126147df384', // 小程序的appid
        //   title: 'this is title', // 小程序消息的title
        //   imgUrl:
        //     'https://search-operate.cdn.bcebos.com/d054b8892a7ab572cb296d62ec7f97b6.png', // 小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
        //   page: '/index/page.html' // 小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
        // }
      }
      await wxSDK.sendChatMessage('text', {
        content: contentJson.text.content
      })
    }
  }
}
</script>

<style lang="less" scope>
.activity-list {
  .top-search-wrapper {
    background: #fff;
    padding: 13px 18px 3px 18px;
  }
  .list-wrapper {
    width: 100%;
    padding: 13px 18px 3px 18px;
    .list-body-card {
      padding: 15px 12px;
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.03);
      border-radius: 4px;
      margin-top: 14px;
      .card-wrapper {
        position: relative;
        .card-title {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 22px;
          margin-bottom: 10px;
        }
        .card-top {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          line-height: 16px;
          display: flex;
          .name {
            color: #333;
            margin-right: 20px;
            max-width: 100px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-family: DFPKingGothicGB-Regular, DFPKingGothicGB;
            font-weight: normal;
          }
          .phone {
            color: #666;
            font-family: DFPKingGothicGB-Regular, DFPKingGothicGB;
            font-weight: normal;
          }
        }
        .card-middle {
          background: #e8e8e8;
          .van-divider {
            margin: 13px 0 11px 0;
          }
        }
        .card-bottom {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 16px;
        }
        .btn {
          display: flex;
          justify-content: flex-end;
          font-family: DFPKingGothicGB-Regular;
          font-size: 12px;
          color: #000000;
          letter-spacing: 0;
          line-height: 21px;
          div {
            margin-left: 30px;
          }
        }
        .card-bg {
          position: absolute;
          width: 63px;
          height: 63px;
          top: -15px;
          right: -12px;
        }
      }
    }
    .list-more {
      font-size: 12px;
      font-family: DFPKingGothicGB-Regular, DFPKingGothicGB;
      font-weight: 400;
      color: #999;
      line-height: 15px;
      text-align: center;
      margin-top: 30px;
    }
  }
  .pop-up-open-btn {
    width: 50px;
    height: 50px;
    background: #020002;
    border-radius: 50%;
    position: fixed;
    bottom: 100px;
    right: 0;
    font-size: 12px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
