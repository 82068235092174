<template>
  <div>
    <div v-if="showPage">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import './constant'
import { mapGetters } from 'vuex'
import { getUser } from '@/scripts/userAuth'
import wxSDK from '@/scripts/wxsdk'
import store from '@/store/index'
export default {
  data() {
    return {
      inited: false
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userAuth/getUsersCurrentInfo'
    }),
    showPage() {
      const auth_type = global.APP_CONFIG.auth_type
      const flag =
        auth_type === 'customAuthLogin' ||
        ((auth_type === 'webAuthLogin' || auth_type === 'scanCodeAuthLogin') &&
          this.userInfo)
      return flag
    }
  },
  watch: {
    async $route(_to, _from) {
      if (!this.inited) {
        await this.init()
      }
    }
  },
  async created() {
    await this.init()
  },
  methods: {
    async init() {
      const auth_type = global.APP_CONFIG.auth_type
      if (auth_type === 'webAuthLogin' || auth_type === 'scanCodeAuthLogin') {
        await getUser()
        await wxSDK.config(
          [],
          [
            'getContext',
            'getCurExternalContact',
            'sendChatMessage',
            'shareAppMessage'
          ]
        )
      }
      else if (auth_type === 'customAuthLogin') {
        await this.customAuthLogin()
      }
      this.inited = true
    },
    async customAuthLogin() {
      // 此处自定义鉴权和sdk接入
      const authorizeInfo = {
        mobile: '13265894707',
        status: 'OK',
        token: '99497ed7-bd46-40c8-8efd-08fe4d6eb31c',
        userId: null,
        qwUserId: 'richxue',
        appId: '1000047'
      }
      const usersCurrentInfo = {
        id: '144115205301725655',
        gender: 'M',
        name: '13265894707',
        isInitial: 'N',
        mobile: '13265894707',
        avatar:
          'http://wework.qpic.cn/bizmail/CWicdedfdKfes0Geb5pH4nmCou99VA5jc72kS0Cl1U6dbXkvViass3EQ/0',
        userName: '薛丰',
        roles: [],
        status: '',
        mainTenant: '',
        tenant: '',
        org: [],
        extra: '',
        birthday: null,
        tenantList: [],
        permApiList: [],
        permIdList: [],
        permList: []
      }
      store.commit('userAuth/authorizeInfo', authorizeInfo)
      store.commit('userAuth/usersCurrentInfo', usersCurrentInfo)
    }
  }
}
</script>

<style lang="less" scoped></style>
